export interface IUser {
	id:string;
	first_name:string;
	last_name:string;
	email:string;
	username:string;
}

export interface IProfile {
	id:string;
	user:IUser;
}

export interface ICustomer {
	id:string;
	name:string;
	no?:string;
	street?:string;
	zipcode?:string;
	city?:string;
	contact?:string;
	phone?:string;
	email?:string;
	erp_id?:string;
	iserp?:boolean;
}



export enum ETaskStatus {
	DONE="DONE",
	TODO="TODO",
	DONT="DONT",
	DELETED="DELETED",
}

export enum ETaskAction {
	EMAIL="EMAIL",
	PHONE="PHONE",
	MEET="MEET",
	TASK="TASK",
}

export interface ITask {
	id:string;
	status:ETaskStatus;
	time:Date;
	alert?:Date;
	action?:ETaskAction;
	description:string;

	contact?:string;
	phone?:string;
	email?:string;
	place?:string;

	target?:IUser | null;
}



export enum ECardStatus {
	NEW="NEW",
	CONTACTED="CONTACTED",
	OFFER="OFFER",
	WON="WON",
	ARCHIVE="ARCHIVE",
}

export interface ICard {
	id:string;
	header:string;
	details:string;
	amount:number;
	stars:number;
	time:Date;
	dueDate?:Date;
	status:ECardStatus;
	tasks?:ITask[];
	color?:string;
	customer?:ICustomer;

	owner?:IUser | null;
}






export interface IItem {
	id: string;
	SKU: string;
  name1: string;
	unitPrice: number;
	serviceLevel: number;
	deliveryTime: number;
}

export interface ISupplier {
	id: string;
  name: string;
	deliveryTime: number;
	shippingCosts: number;
}

export interface IItemSupplier {
	id: string;
	SKU: string;
  name1: string;
	item: IItem;
	unitPrice: number;
	supplier: ISupplier;
	shippingCosts?: number;
}

export enum EPurchaseOrderStatus {
	DRAFT="DRAFT",
	ORDERED="ORDERED",
	SHIPPED="SHIPPED",
	RECEIVED="RECEIVED",
	DELETED="DELETED",
}

export interface IPurchaseOrderRow {
	id: string;
	itemSupplier: IItemSupplier | null;
	orderedQuantity: number;
	shippedQuantity?: number;
	receivedQuantity?: number;
	unitPrice: number;
}


export interface IPurchaseOrder {
	id: string;
	supplier?: ISupplier;
	status: EPurchaseOrderStatus;
	created: Date;
	ordered?: Date;
	shipped?: Date;
	received?: Date;
	deliveryTime?: number;
	shippingCosts?: number;

	rows: Array<IPurchaseOrderRow>;
}

export interface IWarehouse {
	id: string;
	name: string;
}

export interface IWarehouseItem {
	id: string;
	warehouse: IWarehouse;
	item: IItem;
	quantity: number;
}

export interface IUsage {
	id: string;
	item: IItem;
	date: Date;
	quantity: number;
}


/*
Nimikkeet
@SKU
EAN
Nimi1
Nimi2
Hinta?
Ostohinta
Huom-kenttä
Päätoimittaja->@toimittaja
Muut toimittajat->JSON(@toimittaja)
Tuotekategoria->@tuotekategoriat
Yksikkö
Pakkauskoko
pakkauskerroin
 
Tuotekategoriat
@ID
Nimi
 
Tuotenimet
@SKU, @Toim.ID->@Toimittaja
Toimittajan tuotenimi 1
Toimittajan tuotenimi 2
Toimittajan yksikkö
Yksikkökerroin
Toimittajan SKU
 
Ostotilaus
@Tilausnro
ToimID->@Toimittaja
Toim.osID->@Toimosoitteet
Tekstikenttä
StatusID->@Ostatukset
Suunniteltu Toimituspäivä
Toteutunut toimituspäivä
Toimitusaika
 
Ostotilausrivi
@Tilausnro->@Ostotilaus, @rivinro
SKU->@Nimikkeet
Määrä
Avoinna
Hinta
Alepros
Suunniteltu toimituspäivä
Toteutunut toimituspäivä
Toimitusehto->@Toimitusehdot
Toimitustapa->@Toimitustavat
 
Saapuminen
@SaapumisID
ToimittajaID->@Toimittaja
Päivä
Kello
Rahtikirja
Lähetenro
 
Saapumisrivi
@SaapumisID->@Saapuminen, @Rivinro
SKU->@Nimikkeet
Määrä
Kunto
 
Varasto
@SKU->@Nimikkeet
Määrä
Keskihinta
 
Toimittajat
@ToimID
Nimi
Toimitusaika
Valmistusaika
OsoiteID->@Toim.osoitteet
Oletuskontakti->@Toim.kontaktit
Tilauskustannus (per tilaus)
 
Toim.osoitteet
@ToimID->@Toimittajat, @Rivinro
Osoite1
Osoite 1
Postinumero
Postitoimipaikka
Maa
 
Toim.kontaktit
@ToimID->@Toimittajat, @Rivinro
Etunimi
Sukunimi
Puhelinnumero
Sähköposti
Titteli
 
Toim.hinnastot
@Toim.ID->Toimittajat, @Rivinro
HinnastoID->@Toim.hinnasto
Hinnastonnimi
Voimassaolon alku
Voimassaolon loppu
 
Toim.hinnasto
@HinnastoID, @SKU->@Nimikkeet, @Rivinro
Kynnysmäärä (MOQ)
Hinta
 
 
Toimitusehdot
@ID
Selite
 
Toimitustavat
@ID
Selite
 
Myyntitilaus
@Tilausnro
Asiakkaan nimi
Toim.osoite
 
Mtilausrivi
@Tilausnro->Myyntitilaus, @Rivinro
SKU->@Nimikkeet
Määrä
Hinta
 
Yksiköt
@ID
Nimi
 
Tilausstatukset
@StatusID
Nimi
 
Ostotilausstatukset
@StatusID
Nimi
 
 
Viestit
@ID
Teksti
Tyyppi->@Viestityypit
Status->@Viestistatukset
Lähettäjä->@käyttäjät
Vastaanottaja->@käyttäjät
 
Viestistatukset
@ID
Selite
 
Viestityypit
@ID
Selite
 
Käyttäjät
@ID
Nimi
Sähköposti
Puhelin
rooli


Kulutusdata
???
*/





export interface Customer {
  timestamp: number
  name: string
}

export interface Cell {
  value: string
	readOnly?: boolean,
}

export interface WorkTime {
	start: Date
	stop: Date
}

export interface InvoiceRow {
  timestamp: number
	item: string
	quantity: number
	price:number
	alv:number
}

export interface ProductHistory {
  timestamp: number
	item: string
	quantity: number
}


export interface Invoice {
  timestamp: number
  customer?: string
	items: InvoiceRow[]
}

export interface Message {
  user: string
  message: string
  timestamp: number
}

export interface ChatState {
	users: IUser[],
	cards: ICard[],
	customers : ICustomer[],

	loggedIn: boolean,
	userIn: string,
	customersLoaded:boolean,

	items: IItem[],
	suppliers: ISupplier[],
	itemSuppliers:IItemSupplier[],
	orders:IPurchaseOrder[],
	orderRows:IPurchaseOrderRow[],
	usage: IUsage[],
/*
  messages: Message[]
	customers: Customer[]
	productstable: Cell[][]
	worktimes: WorkTime[]
	invoicerows: InvoiceRow[]
	invoice: Invoice
	productshistory: ProductHistory[]
*/	
}

export const SEND_MESSAGE = 'SEND_MESSAGE'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const DB_INITIALIZE = 'DB_INITIALIZE'

export const DB_UPDATE = 'DB_UPDATE'

interface DbUpdateAction {
	type: typeof DB_UPDATE
	payload: ChatState
}

interface SendMessageAction {
  type: typeof SEND_MESSAGE
  payload: Message
}

interface UpdateMessageAction {
  type: typeof UPDATE_MESSAGE
  payload: Message
}


interface DeleteMessageAction {
  type: typeof DELETE_MESSAGE
  meta: {
    timestamp: number
  }
}

interface DbInitializeAction {
  type: typeof DB_INITIALIZE
	state: ChatState
}

export type ChatActionTypes = SendMessageAction | DeleteMessageAction | UpdateMessageAction | DbInitializeAction | DbUpdateAction
