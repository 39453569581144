import React, { useState } from 'react';

import { connect } from "react-redux";
import { AppState } from "./store";


import { Input, Button, Modal, ModalBody, Alert } from 'reactstrap';

import { dbUpdate } from "./store/chat/actions";

import axios from './axios';

interface IProps {
	dbUpdate: typeof dbUpdate;
	loggedIn: boolean;
}

const classes=require('./LoginModal.module.scss');

const CardOpen: React.FC<IProps> = ({
	dbUpdate,
	loggedIn,
}) => {

	const [loginForm, setLoginForm]=useState<any>({username:"",password:""});
	const [message,setMessage]=useState<string>("");

	const login = () => {
		axios.defaults.xsrfCookieName=undefined;
		axios.defaults.xsrfHeaderName=undefined;
		axios.post("login",{username:loginForm.username,password:loginForm.password})
			.then((resp)=>{
				dbUpdate({loggedIn:true});
				window.location.reload();
			})
			.catch( (error)=>{
				setMessage("virhe kirjautumisessa");
			})
	}

	const onChange = (name:string) => (e:any) => {
		setLoginForm({...loginForm,[name]:e.target.value})
	}

	return (		
		<Modal isOpen={!loggedIn} centered>
			<ModalBody>
				<div className={classes.title}>
					Kirjaudu sisään
				</div>
				{message &&<Alert color="danger">{message}</Alert>}
				<div className={classes.label}>Käyttäjätunnus</div>
				<Input value={loginForm.username} onChange={onChange("username")}/>
				<div className={classes.label}>Salasana</div>
				<Input type="password" value={loginForm.password} onChange={onChange("password")}/>

				<div className={classes.btn}>
					<Button color="primary" onClick={login}>Kirjaudu</Button>
				</div>
        </ModalBody>

			</Modal>
	);
}

const mapStateToProps = (state: AppState) => ({
	loggedIn: state.chat.loggedIn,
});

export default connect(
  mapStateToProps,
	{ dbUpdate }
)(CardOpen);

