import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import { AppState } from "./store";


import { Route, BrowserRouter as Router } from 'react-router-dom'

import { LocalizedSwitch, LocalizedRouter, appStrings } from './i18n';
import { AppLanguage, AppRoute } from './i18n/const';

import NotFound from './NotFound';

import Main from './Main';
import Todo from './Todo';
import Customers from './Customers';
import Calendar from './Calendar';


import LoginModal from './LoginModal';

//import Item from './items/Item';
//import Supplier from './suppliers/Supplier';
//import Order from './orders/Order';
//import Warehouse from './warehouse/Warehouse';
//import Usage from './usage/Usage';

import './App.css';

import { loadCards, loadUsers, loadCustomers } from './InitScript'
import { dbUpdate } from "./store/chat/actions";

import { ICustomer } from "./store/chat/types";

import MenuBar from './MenuBar';

import axios from './axios';


interface IProps {
	defaultLanguage?: AppLanguage;
	dbUpdate: typeof dbUpdate;
	customers: ICustomer[];
	loggedIn:boolean,
	userIn:string,
}


const App: React.FC<IProps> = ({
	defaultLanguage,
	dbUpdate,
	customers,
	loggedIn,
	userIn,
}) => {

	const [init, setInit]=useState<boolean>(false);

	if(!init) {
		setInit(true);

		console.log("INIT")

		axios.get("login").then((resp)=>{
      console.log("LOGIN",resp) //#.data)
			if(resp.data.username) {
				console.log("KO")
				dbUpdate({
					loggedIn:true,
					userIn:resp.data.username,
					//				user:resp.data as IUser
				})


				setTimeout(()=>{
					loadUsers(dbUpdate);
					loadCustomers(dbUpdate,(xcustomers)=>{
						loadCards(dbUpdate,xcustomers);
					});
				},100);
			}
			else {
				sessionStorage.clear();
				localStorage.clear();				
			}



		}).catch(()=>{
			sessionStorage.clear();
			localStorage.clear();

			//			window.location.reload();
		})
	}
		/*
	if(!init && loggedIn) {
		console.log("INIT");
		setInit(true);
		//loadTasks(dbUpdate,customers);
		loadCards(dbUpdate,customers);


	}
		 */
	return (
	<LocalizedRouter
		RouterComponent={Router}
		languages={AppLanguage}
		appStrings={appStrings}
		defaultLanguage={defaultLanguage}
	>
		<MenuBar userIn={loggedIn?userIn:""}
/>

		<div className="mainz">
			<LocalizedSwitch>
				<Route exact path={AppRoute.Home} component={Main} />
				<Route exact path={AppRoute.Todo} component={Todo} />
				<Route exact path={AppRoute.Customers} component={Customers} />
				<Route exact path={AppRoute.Calendar} component={Calendar} />

				{/*<Route exact path={AppRoute.Contacts} component={Todo} />*/}

				{/*
				<Route exact path={AppRoute.Items} component={Item} />
				<Route exact path={AppRoute.Suppliers} component={Supplier} />
				<Route exact path={AppRoute.Orders} component={Order} />
				<Route exact path={AppRoute.Warehouse} component={Warehouse} />
				*/}

				<Route component={NotFound} />
			</LocalizedSwitch>
		</div>

		<LoginModal />

 	</LocalizedRouter>


  );
}

const mapStateToProps = (state: AppState) => ({
	cards: state.chat.cards || [],
	customers: state.chat.customers || [],
	loggedIn: state.chat.loggedIn,
	userIn: state.chat.userIn,
});

export default connect(
  mapStateToProps,
	{ dbUpdate }
)(App);

