import React, { useState } from 'react';

import { connect } from "react-redux";
import { AppState } from "./store";

import { dbUpdate } from "./store/chat/actions";

import Customer from './components/Customer/Customer';

import { ICard, ICustomer } from './store/chat/types';

import { Container, Button, Input, Row, Col, ButtonGroup } from 'reactstrap';

import { v4 as uuidv4 } from 'uuid';

import axios from './axios';

import { ytjLoad2, loadDetails2 } from './include/ytj';

const classes=require('./Main.module.scss');
//const classes=require('./Customers.module.scss');


interface IProps {
	cards: ICard[];
	customers: ICustomer[];
	dbUpdate: typeof dbUpdate;
	customersLoaded:boolean;
}

const Customers: React.FC<IProps> = ({
	cards,
	customers,
	dbUpdate,
	customersLoaded,
}) => {

	const [companySearch,setCompanySearch]=useState<string>("");
	const [companyResults,setCompanyResults]=useState<any>(null);
	const [companyResults2,setCompanyResults2]=useState<any>(null);

	const [loading, setLoading]=useState(false);

	const nextResults=(companyResults2 || {nextResultsUri:null}).nextResultsUri || ""
	const prevResults=(companyResults2 || {previousResultsUri:null}).previousResultsUri || ""


	const ytjLoad = (uri?:string) => {
		setLoading(true);
		ytjLoad2(companySearch,(data:any)=>{
			if(data) {
				setCompanyResults2(data)
				setCompanyResults(Object.assign({},...data.results.map((ob:any)=>({[ob.businessId]:ob}))));
				setCompanySearch("");
			}
			else {
				alert("Virhe")
			}
			setLoading(false);
		},uri)
	}

	const loadDetails = (businessId:string) => {
		loadDetails2(businessId,(data:any)=>{
				const res={...companyResults}
				res[businessId]=data
				setCompanyResults(res)
		})
	}
	
	const customerSearch = (search:string, callback?:(data:any)=>void) => {
		setLoading(true);
		ytjLoad2(search.trim().replace(/\s/g," "),(data:any)=>{
			if(data) {
				setCompanyResults2(data)
				setCompanyResults(Object.assign({},...data.results.map((ob:any)=>({[ob.businessId]:ob}))));

				if(callback) {
					callback(data)
				}
			}
			else {
				alert("Ei löydy hakusanalla: "+search)
			}
			setLoading(false);
		})

	}

	const loadCustomers = () => {
		axios.get("getdata?model=customers1").then((resp)=>{
  	  if(resp.data.status==="auth") {
				dbUpdate({loggedIn:false});
  	  }
			else {
				const overload:string[]=[]
				const tc:ICustomer[]=[]
				Object.values(resp.data).map((ob:any)=>{				
					const c:ICustomer={
						id:ob.id,
						erp_id:ob.attrs.erp_id,
						name:ob.name,
						no:ob.attrs.no,
						street:ob.attrs.street,
						zipcode:ob.attrs.zipcode,
						city:ob.attrs.city,
						contact:ob.attrs.contact,
						phone:ob.attrs.phone,
						email:ob.attrs.email,
					}
					tc.push(c)
					if(ob.attrs.erp_id) { overload.push(ob.attrs.erp_id) }
				})
				//dbUpdate({customers:tc})

				loadCustomers2(tc,overload)
  	  }
  	});
	}


	const loadCustomers2 = (preload:ICustomer[],overload:string[]) => {
		axios.get("getdata?model=customers2").then((resp)=>{
  	  if(resp.data.status==="auth") {
				dbUpdate({loggedIn:false});
  	  }
			else {
				const tc:ICustomer[]=preload
				Object.values(resp.data).map((ob:any)=>{
					if(!overload.includes(ob.id)) {
						const c:ICustomer={
							id:ob.id,
							erp_id:ob.id,
							iserp:true,
							name:ob.name,
							no:ob.attrs.customerid,
						}
						tc.push(c)
					}
				})
				
				dbUpdate({customers:tc})

  	  }
  	});
	}

	if(!customersLoaded) {
		dbUpdate({customersLoaded:true});
		loadCustomers();
	}

	const newCard = () => {
		dbUpdate({customers:[...customers,{
			id:uuidv4(),
			name:"Uusi asiakas",
		}]})
	}

	const customersSort = (a:ICustomer, b:ICustomer) => {
		const an:string=a.name || ""
		const bn:string=b.name || ""
		return(
			an.toLowerCase()<bn.toLowerCase()?-1:1
		)
	}

	const atname = (name:string) => {
		return name.toLowerCase().replace(/\s/g,"-")
	}

	const address = (j:Array<any>) => {
		const ret=(j || []).filter((ob:any)=>(ob.endDate===null)).map((ob:any)=>((ob.careOf?("c/o "+ob.careOf+"\n"):"")+ob.street+"\n"+ob.postCode+" "+ob.city+"\n")) //+JSON.stringify(ob)))
		return [...new Set(ret)]
	}

	const contact = (j:Array<any>) => {
		const ret=(j || []).filter((ob:any)=>(ob.endDate===null && ob.language==="FI")).map((ob:any)=>(ob.type+": "+(ob.value || "")+"\n")) //+JSON.stringify(ob)))
		return [...new Set(ret)]
	}

	const businessLines = (j:Array<any>) => {
		const ret=(j || []).filter((ob:any)=>(ob.language==="FI")).map((ob:any)=>((ob.name)+"\n")) //+JSON.stringify(ob)))
		return [...new Set(ret)]
	}

	const auxiliaryNames = (j:Array<any>) => {
		const ret=(j || []).map((ob:any)=>((ob.name+"\n"))) //+JSON.stringify(ob)))
		return [...new Set(ret)]
	}
	
	const isDead = (ob:any) => (ob.registeredEntries && ob.registeredEntries.length>0 && ob.registeredEntries.pop().description==="Ceased")

	return (<div className="customerview"><Container>
		{Object.values(companyResults || {}).map((ob:any,index:number) => (<Row key={index}>
			<Col className={classes.companyrow+" "+(isDead(ob)?classes.dead:"")}>
				<a href={"https://www.kauppalehti.fi/yritykset/yritys/"+atname(ob.name)+"/"+ob.businessId} target="_blank">{ob.name}</a>
				{auxiliaryNames(ob.auxiliaryNames).map((ob,index)=>(<pre className={classes.pre2} key={index}>{ob}</pre>))}
				{auxiliaryNames(ob.names).map((ob,index)=>(<pre className={classes.pre2} key={index}>{ob}</pre>))}

			</Col>
				<Col className="text-right">{ob.businessId}</Col>
				<Col md={5}>
					{businessLines(ob.businessLines).map((ob,index)=>(<pre className={classes.pre} key={index}>{ob}</pre>))}
					{address(ob.addresses).map((ob,index)=>(<pre className={classes.pre} key={index}>{ob}</pre>))}
					{contact(ob.contactDetails).map((ob,index)=>(<pre className={classes.pre} key={index}>{ob}</pre>))}

					{ob.detailsUri && <Button size="sm" onClick={()=>loadDetails(ob.businessId)}>Lisätietoja</Button>}
				</Col>
			</Row>))}
		
	
			<ButtonGroup className="w-100">
				{prevResults && <Button disabled={loading} onClick={()=>ytjLoad(prevResults)}>Hae edelliset</Button>}
				{nextResults && <Button disabled={loading} onClick={()=>ytjLoad(nextResults)}>Hae lisää</Button>}
			</ButtonGroup>
	
			<Input placeholder="etsi" readOnly={loading} value={companySearch} onChange={(e)=>setCompanySearch(e.target.value)} />

			<div className="text-right">
				<Button color="primary" disabled={loading || companySearch.length<3} onClick={()=>ytjLoad()}><i className={(loading?"loading ":"")+"fas fa-sync"}/> Etsi</Button>
			</div>


		{customers.sort(customersSort).map((ob,i)=>{
			return(<div key={i}>
				<Customer customer={ob} search={customerSearch}/>
			</div>)
		})}


		<div className={classes.bottombtn}>
			<Button color="primary" onClick={newCard}><i className="fas fa-plus"/> Uusi asiakas</Button>
			{' '}<Button color="secondary" onClick={loadCustomers}>Päivitä</Button>

		</div>

	</Container>
	</div>);
}

const mapStateToProps = (state: AppState) => ({
	cards: state.chat.cards,
	customers: state.chat.customers,
	customersLoaded: state.chat.customersLoaded,
});

export default connect(
  mapStateToProps,
  { dbUpdate }
)(Customers);

