import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import { AppState } from "../../store";


import { Button, Badge, Col, Row, Input, InputGroup } from 'reactstrap';

import { ICustomer, ICard } from '../../store/chat/types';

import { dbUpdate } from "../../store/chat/actions";

import Card from '../Card/Card';

import axios from '../../axios';

interface IProps {
	customer:ICustomer,
		search:(name:string,callback?:(data:any)=>void)=>void;

	cards:ICard[];
	customers:ICustomer[],
	dbUpdate: typeof dbUpdate;
}

const classes=require('./Customer.module.scss');

const Customer: React.FC<IProps> = ({
	customer,
	search,

	cards,
	customers,
	dbUpdate,
}) => {
	const [editCustomer,setEditCustomer]=useState<ICustomer>(customer);
	const [detailsOpen, setDetailsOpen]=useState<{[id:string]:boolean}>({});

	const [projects, setProjects]=useState<any>(null); 

	useEffect(()=>{
		setEditCustomer(customer);
	},[customer])

	const customerCards = cards.filter((ob)=>ob.customer && ob.customer.id === customer.id)

	const onChange = (name:string) => (e:any) => {
		setEditCustomer({...editCustomer,[name]:e.target.value})
	}

	const save = () => {
		const i=[...customers]
		const index=i.findIndex((ob)=>ob.id===editCustomer.id)
		//		i[index]=editCustomer
		//dbUpdate({customers:i})
		
		updateCustomer(editCustomer,i,index)
	}

	const updateCustomer = (data:ICustomer,i:ICustomer[],index:number) => {
		const data2send:any={
			id:data.iserp?null:data.id,
			name:data.name,
			attrs:{
				erp_id:data.erp_id,
				no:data.no,
				street:data.street,
				zipcode:data.zipcode,
				city:data.city,
				contact:data.contact,
				phone:data.phone,
				email:data.email,
			},
		}

		axios.post("getdata",{model:"customers1",data:data2send}).then((resp)=>{
  	  if(resp.data.status==="auth") {
				dbUpdate({loggedIn:false});
  	  }
			else {
				const ob=resp.data
					const c:ICustomer={
						id:ob.id,
						erp_id:ob.attrs.erp_id,
						name:ob.name,
						no:ob.attrs.no,
						street:ob.attrs.street,
						zipcode:ob.attrs.zipcode,
						city:ob.attrs.city,
						contact:ob.attrs.contact,
						phone:ob.attrs.phone,
						email:ob.attrs.email,
					}

				if(index!==-1) {
					i.splice(index,1);
				}
				i.push(c)
				//				i[index]=ob
				dbUpdate({customers:i})

				/*
				const tc:ICustomer[]=[]
				Object.values(resp.data).map((ob:any)=>{				
					const c:ICustomer={
						id:ob.id,
						name:ob.name,
						no:ob.attrs.customerid,
					}
					tc.push(c)
				})
				dbUpdate({customers:tc})
				 */
  	  }
  	});
	}

	const searchUpdate = (data:any) => {
		if(data.results.length===1) {
			const j=data.results[0].addresses
			const ret=(j || []).filter((ob:any)=>(ob.endDate===null)).map((ob:any)=>((ob.careOf?("c/o "+ob.careOf+" "):"")+ob.street+";"+ob.postCode+";"+ob.city))
			const address=[...new Set(ret)][0]
			if(address) {
				const [street,zip,city]=(address as string).split(";")
				setEditCustomer({...editCustomer,street:street,zipcode:zip,city:city})
			}
		}
	}

	const edited=JSON.stringify(customer)!==JSON.stringify(editCustomer)
	
	if(detailsOpen[customer.id] && projects===null && customer.erp_id) {
		axios.get("getdata?model=projects2&parent_id="+customer.erp_id).then((resp)=>{
			setProjects(resp.data)
		})
	}

	return !customer?null:(<div className={classes.customer}>
			<Row onClick={()=>setDetailsOpen({...detailsOpen,[customer.id]:!detailsOpen[customer.id]})} className={customer.iserp?classes.iserp:classes.noerp}>
				<Col xs={1}><i className={"fas fa-caret-"+(detailsOpen[customer.id]?"down":"right")}/></Col>
				<Col>				
					<div>{customer.name} {customerCards.length>0 && <Badge>{customerCards.length}</Badge>}</div>
				</Col>
			</Row>
			
			{detailsOpen[customer.id] && <>
				<div className={classes.details+" "+(edited?classes.edited:"")}>
				<Row>
					<Col md={8}>Asiakas
						<InputGroup>
							<Input value={editCustomer.name} onChange={onChange("name")}/>
							<Button onClick={()=>search(editCustomer.name,searchUpdate)}><i className="fas fa-search"/></Button>
						</InputGroup>
					</Col>
					<Col>Asiakasnumero<Input value={editCustomer.no} onChange={onChange("no")}/></Col>
				</Row>
				<Row>
					<Col>Katu<Input value={editCustomer.street} onChange={onChange("street")}/></Col>
					<Col md={3}>Postinumero<Input value={editCustomer.zipcode} onChange={onChange("zipcode")}/></Col>
					<Col md={4}>Postitoimipaikka<Input value={editCustomer.city} onChange={onChange("city")}/></Col>
				</Row>

				<Row><Col>Yhteyshenkilö<Input value={editCustomer.contact} onChange={onChange("contact")}/></Col></Row>
				<Row>
					<Col md={4}>Puhelinnumero<Input value={editCustomer.phone} onChange={onChange("phone")}/></Col>
					<Col>Sähköposti<Input value={editCustomer.email} onChange={onChange("email")}/></Col>
				</Row>
				{projects && Object.values(projects).map((ob:any)=>(<div className={classes.projectBox}>
					<div className={classes.projectName}>{ob.name}</div>
					<div>{ob.attrs.street} {ob.attrs.zipcode} {ob.attrs.city}</div>
					<div>{ob.attrs.contact_name} {ob.attrs.contact_email} {ob.attrs.contact_phone}</div>
					{ob.description}
				</div>))}
				{edited && <div className="text-right">
					<Button color="primary" onClick={save}>Tallenna muutokset</Button>
				</div>}
			</div>

				<Row>
					<Col>
						<br/>
						{customerCards.map((card)=>(
							<Card key={card.id} data={card} badge/>
						))}
					</Col>
				</Row>
		</>}

	</div>);
}

const mapStateToProps = (state: AppState) => ({
	cards: state.chat.cards,
	customers:state.chat.customers,
});

export default connect(
  mapStateToProps,
	{ dbUpdate }
)(Customer);

