import React, { useState } from 'react';

import Stars from '../Stars/Stars';

import CardOpen from './CardOpen';

import { newDate } from '../../newDate';

import { Badge } from 'reactstrap';

import { ICard, ETaskStatus, ECardStatus } from '../../store/chat/types';

interface IProps {
	data:ICard;
	badge?:boolean;
}

const current = (d:number) => {
	return (d || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')+"€";
}

const classes=require('./Card.module.scss');

const Card: React.FC<IProps> = ({
	data,
	badge,
}) => {
	const [isOpen,setIsOpen]=useState(false);

	const close = () => setIsOpen(false);
	const open = () => setIsOpen(true);

	if(!data) return null;
	const status=data.status?{
		[ECardStatus.NEW]:"UUSI",
		[ECardStatus.CONTACTED]:"KONTAKTOITU",
		[ECardStatus.OFFER]:"TARJOUS",
		[ECardStatus.WON]:"VOITETTU",
		[ECardStatus.ARCHIVE]:"POISTETTU",

	}[data.status]:""

	//	const totalTasks=(data.tasks || []).length
	const totalTasks=(data.tasks || []).filter((ob)=>![ETaskStatus.DONT, ETaskStatus.DELETED].includes(ob.status) ).length

	const openTasks=(data.tasks || []).filter((ob)=>ob.status===ETaskStatus.DONE).length

	return (<><div className={classes.container+" "+classes[data.color || classes.color1]} onClick={open}>
		{badge && <Badge>{status}</Badge>}
		<div className={classes.header}>
			<div className="float-right">{data.owner?data.owner.username:""}</div>
			{data.header}<br/>{data.customer && data.customer.name}</div>
			<div className={classes.amount}>{current(data.amount)}</div>

			<div className={classes.stars}><Stars total={5} stars={data.stars}/></div>
			<pre className={classes.details}>{data.details}</pre>

			<div><i className="fas fa-tasks"/> {openTasks} / {totalTasks}</div>
			<div className={classes.time}>
				<i className="far fa-clock"/>{' '}
				{newDate(data.time).printdate()} - {' '}
				{newDate(data.dueDate).printdate()}

			</div>
			{/*<i className="fas fa-envelope"/> <i className="fas fa-phone"/></div>*/}
		</div>
		<CardOpen isOpen={isOpen} close={close} data={data}/>
	</>);
}

export default Card;
