import React, { useState } from 'react';

import { connect } from "react-redux";
import { AppState } from "./store";

import { dbUpdate } from "./store/chat/actions";

import Customer from './components/Customer/Customer';

import { ICard, ICustomer } from './store/chat/types';

import { Button, Input, Row, Col, ButtonGroup } from 'reactstrap';

import { v4 as uuidv4 } from 'uuid';

import axios from './axios';

const classes=require('./Main.module.scss');
//const classes=require('./Calendar.module.scss');


interface IProps {
	cards: ICard[];
	customers: ICustomer[];
	dbUpdate: typeof dbUpdate;
	customersLoaded:boolean;
}

const Calendar: React.FC<IProps> = ({
	cards,
	customers,
	dbUpdate,
	customersLoaded,
}) => {
	return (<div>
		kalenteri
	</div>)
}

const mapStateToProps = (state: AppState) => ({
	cards: state.chat.cards,
	customers: state.chat.customers,
	customersLoaded: state.chat.customersLoaded,
});

export default connect(
  mapStateToProps,
  { dbUpdate }
)(Calendar);

