import axios from './axios';

import { ICard, ICustomer, ITask, IUser, IProfile } from './store/chat/types';
/*
export const loadTasks = (dbUpdate:any, cards:ICard[]) => {
	axios.get("getdata?model=tasks").then((resp)=>{
    if(resp.data.status==="auth") {
			dbUpdate({loggedIn:false});
    }
		else {
			const tc:ITask[]=[]
			//const ncards=[...cards]
			Object.values(resp.data).map((ob:any)=>{
				const card=cards.find((c)=>c.id===ob.card_id)
				if(card) {
					card.tasks=[...card.tasks || [],model2ITask(ob)]
				}
			})
			console.log(cards)
			dbUpdate({cards:[...cards]})
    }
  });

}
*/

	const loadCustomers2 = (dbUpdate:any, preload:ICustomer[],overload:string[], callBack:(customers:any)=>void) => {
		console.log(overload)
		axios.get("getdata?model=customers2").then((resp)=>{
  	  if(resp.data.status==="auth") {
				dbUpdate({loggedIn:false});
  	  }
			else {
				const tc:ICustomer[]=preload
				Object.values(resp.data).map((ob:any)=>{
					if(!overload.includes(ob.id)) {
						const c:ICustomer={
							id:ob.id,
							erp_id:ob.id,
							iserp:true,
							name:ob.name,
							no:ob.attrs.customerid,
						}
						tc.push(c)
					}
				})
				
				dbUpdate({customers:tc})
				callBack(tc);
  	  }
  	});
	}


export const loadCustomers = (dbUpdate:any, callBack:(customers:any)=>void) => {
		axios.get("getdata?model=customers1").then((resp)=>{
  	  if(resp.data.status==="auth") {
				dbUpdate({loggedIn:false});
  	  }
			else {
				const overload:string[]=[]
				const tc:ICustomer[]=[]
				Object.values(resp.data).map((ob:any)=>{				
					const c:ICustomer={
						id:ob.id,
						erp_id:ob.attrs.erp_id,
						name:ob.name,
						no:ob.attrs.no,
						street:ob.attrs.street,
						zipcode:ob.attrs.zipcode,
						city:ob.attrs.city,
						contact:ob.attrs.contact,
						phone:ob.attrs.phone,
						email:ob.attrs.email,
					}
					tc.push(c)
					if(ob.attrs.erp_id) { overload.push(ob.attrs.erp_id) }
				})
				loadCustomers2(dbUpdate, tc,overload, callBack)
  	  }
  	});
	}



export const loadUsers = (dbUpdate:any) => {
	axios.get("getdata?model=users").then((resp)=>{
    if(resp.data.status==="auth") {
			dbUpdate({loggedIn:false});
    }
		else {
			const tc:IUser[]=[]
			Object.values(resp.data as IProfile[]).map((ob:IProfile)=>{
				const c:IUser={
					id:ob.user.id,
					first_name:ob.user.first_name,
					last_name:ob.user.last_name,
					email:ob.user.email,
					username:ob.user.username,
				}
				tc.push(c)
			})
			dbUpdate({users:tc})
			//console.log(tc)
			//loadTasks(dbUpdate,tc)
    }
  });
}


export const loadCards = (dbUpdate:any, customers:ICustomer[]) => {
	axios.get("getdata?model=cards").then((resp)=>{
    if(resp.data.status==="auth") {
			dbUpdate({loggedIn:false});
    }
		else {
			const tc:ICard[]=[]
			Object.values(resp.data).map((ob:any)=>{
				const c:ICard=model2ICard(ob,customers);
				tc.push(c)
			})
			dbUpdate({cards:tc})
			//loadTasks(dbUpdate,tc)
    }
  });
}

export const model2ICard = (ob:any,customers:ICustomer[]):ICard => {
	const attrs=(ob.attrs || {})
	const tasks=(ob.tasks || [])

	const c:ICard={
		id:ob.id,
		header:ob.name,
		details:attrs.details,
		amount:attrs.amount,
		stars:attrs.stars,
		time:attrs.time,
		dueDate:attrs.dueDate,
		status:attrs.status,
		//						tasks:ob.attrs.tasks,
		color:attrs.color,

		owner:attrs.owner,

		tasks:tasks.map((tob:any)=>model2ITask(tob)),
	}

	if(ob.customer_id && customers) {
		c.customer=customers.find((c)=>c.id===ob.customer_id)
	}

	return c
}

export const ICard2model = (data:ICard):any => {
	return {
			id:data.id,
			name:data.header,
			customer_id:data.customer && data.customer.id,
			attrs:{
				details:data.details,
				amount:data.amount,
				stars:data.stars,
				time:data.time,
				dueDate:data.dueDate,
				status:data.status,
				color:data.color,
				owner:data.owner,
			},

			tasks:(data.tasks || []).map((t)=>ITask2model(t))
	}
}

//--------------------------------------------------------------------------

export const model2ITask = (ob:any):ITask => {
	const c:ITask={
		id:ob.id,
		status:ob.attrs.status,
		time:ob.attrs.time,
		alert:ob.attrs.alert,
		action:ob.attrs.action,
		description:ob.attrs.description,

		contact:ob.attrs.contact,
		phone:ob.attrs.phone,
		email:ob.attrs.email,
		place:ob.attrs.place,

		target:ob.attrs.target,
	}

	return c
}

export const ITask2model = (data:ITask):any => {
	return {
			id:data.id,
			attrs:{
				status:data.status,
				time:data.time,
				alert:data.alert,
				action:data.action,
				description:data.description,

				contact:data.contact,
				phone:data.phone,
				email:data.email,
				place:data.place,

				target:data.target,
			},
	}
}
