import React, { useState } from 'react';

import { connect } from "react-redux";
import { AppState } from "../../store";


import { Col, Row, Input, Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { ICustomer, ICard, IUser, ECardStatus, ITask, ETaskAction, ETaskStatus } from '../../store/chat/types';

import { newDate } from '../../newDate';
import { dbUpdate } from "../../store/chat/actions";

import Stars from '../Stars/Stars';

import DatePicker from 'react-datepicker';

import SelectCreatable from 'react-select/creatable'
import Select from 'react-select'

import EditTask from './EditTask';
import Colors from './Colors';

import { v4 as uuidv4 } from 'uuid';

import axios from '../../axios';

import { model2ICard, ICard2model } from '../../InitScript';

import TextareaAutosize from 'react-textarea-autosize';


interface IProps {
	cards:ICard[];
	users:IUser[];
	customers: ICustomer[];
	data:ICard;
	isOpen:boolean;
	close:()=>void;
	dbUpdate: typeof dbUpdate;
	hilight?:string;
}

const classes=require('./Card.module.scss');

const CardOpen: React.FC<IProps> = ({
	cards,
	users,
	customers,
	data,
	isOpen,
	close,
	dbUpdate,
	hilight,
}) => {

	const [editItem, setEditItem]=useState<ICard | null>(data);
	const [editTask, setEditTask]=useState<string | null>(hilight || null);


	const update = (field:keyof ICard, value:any) => {
		if(editItem) {
			setEditItem({...editItem,[field]:value})
		}
	}
	
	const updateTask = (task:ITask) => {
		if(editItem) {
			const i=[...editItem.tasks]
			const index=i.findIndex((ob)=>ob.id===task.id)
			i[index]=task
			update("tasks",i);
		}
	}

	const sortTasks = (a:ITask,b:ITask) => {
		return (newDate(a.time)<newDate(b.time)?-1:1)
	}

	const saveAndClose = () => {
		if(editItem) {
			const i=[...cards]
			const index=i.findIndex((ob)=>ob.id===editItem.id)
			
			updateCard(editItem,i,index)
		}
		close();
	}

	const updateCard = (data:ICard,i:ICard[],index:number) => {
		const data2send:any=ICard2model(data);

		axios.post("getdata",{model:"cards",data:data2send}).then((resp)=>{
  	  if(resp.data.status==="auth") {
				dbUpdate({loggedIn:false});
  	  }
			else {
				const ob={attrs:{},...resp.data}

				const c:ICard=model2ICard(ob,customers);
				if(index!==-1) {
					i[index]=c
					//i.splice(index,1);
				}
				else {
					i.push(c)
				}
				dbUpdate({cards:[...i]})

				setEditItem(c)
  	  }
  	});
	}


	const updateCustomer = (data:ICustomer,i:ICustomer[],index:number) => {
		const data2send:any={
			id:null,
			name:data.name,
			attrs:{
				no:data.no,
				street:data.street,
				zipcode:data.zipcode,
				city:data.city,
				contact:data.contact,
				phone:data.phone,
				email:data.email,
			},
		}

		axios.post("getdata",{model:"customers1",data:data2send}).then((resp)=>{
  	  if(resp.data.status==="auth") {
				dbUpdate({loggedIn:false});
  	  }
			else {
				const ob={attrs:{},...resp.data}
					const c:ICustomer={
						id:ob.id,
						name:ob.name,
						no:ob.attrs.no,
						street:ob.attrs.street,
						zipcode:ob.attrs.zipcode,
						city:ob.attrs.city,
						contact:ob.attrs.contact,
						phone:ob.attrs.phone,
						email:ob.attrs.email,
					}

				if(index!==-1) {
					i.splice(index,1);
				}
				i.push(c)
				dbUpdate({customers:i})

				update("customer",c)

  	  }
  	});
	}


	const createCustomer = (name:string) => {
		const i=[...customers]

		const n:ICustomer={
			id:uuidv4(),
			name:name,
		}
		updateCustomer(n,i,-1)

		//		update("customer",n)
		//		console.log("FIX");
		//		dbUpdate({customers:[...i,n]})
	}

	const archiveCard = () => {
		if(editItem) {
			const i=[...cards]
			const index=i.findIndex((ob)=>ob.id===editItem.id)
			
			updateCard({...editItem,status:ECardStatus.ARCHIVE},i,index)
		}
		close();

	}

	const addTask = () => {
		const d=new Date();
		const m = (((d.getMinutes() + 7.5)/15 | 0) * 15) % 60;
		const h = ((((d.getMinutes()/105) + .5) | 0) + d.getHours()) % 24;

		d.setHours(h,m,0,0);

		const newTask:ITask={
			id:uuidv4(),
			status:ETaskStatus.TODO,
			action:ETaskAction.TASK,
			time:d,
			description:"",
		}

		if(editItem) {
			update("tasks",[...(editItem.tasks || []),newTask])
			setEditTask(newTask.id)
		}

	}

	type SelectOption = {label:string,value:ICustomer}
	//	const optionsMapper:SelectOption = (ob:ICustomer) => ({value:ob,label:ob.name})


	return !editItem?null:(
		<Modal isOpen={isOpen}>
			<ModalHeader toggle={()=>saveAndClose()} className={classes.mh}><Input value={editItem.header} onChange={(e)=>update("header",e.target.value)}/></ModalHeader>
			<ModalBody>
				<ButtonGroup className="w-100">
					<Button onClick={()=>update("status",ECardStatus.NEW)} color={editItem.status===ECardStatus.NEW?"primary":"secondary"}>UUSI</Button>
					<Button onClick={()=>update("status",ECardStatus.CONTACTED)} color={editItem.status===ECardStatus.CONTACTED?"primary":"secondary"}>KONTAKTOITU</Button>
					<Button onClick={()=>update("status",ECardStatus.OFFER)} color={editItem.status===ECardStatus.OFFER?"primary":"secondary"}>TARJOUS</Button>
					<Button onClick={()=>update("status",ECardStatus.WON)} color={editItem.status===ECardStatus.WON?"primary":"secondary"}>VOITETTU</Button>
		{/*					<Button onClick={()=>update("status",ECardStatus.ARCHIVE)}color={editItem.status===ECardStatus.ARCHIVE?"primary":"secondary"}>POISTETTU</Button>*/}

				</ButtonGroup>
				
				<Select
					placeholder="Omistaja..."
					isClearable
					options={users.map((ob)=>({value:ob,label:ob.first_name+" "+ob.last_name}))}
					onChange={(e:any)=>update("owner",e && e.value)}
					value={editItem.owner && {value:editItem.owner,label:editItem.owner.first_name+" "+editItem.owner.last_name}}
				/>

				<SelectCreatable
					placeholder="Asiakas..."
					isClearable
					options={customers.filter((ob)=>!ob.iserp).map((ob) => ({value:ob,label:ob.name}))}
					onChange={(e:any)=>update("customer",e && e.value)}
					value={editItem.customer && {value:editItem.customer,label:editItem.customer.name}}
					onCreateOption={(e)=>createCustomer(e)}
				/>

				<Row>
					<Col>
						Arvo
						<div className={classes.amount}><Input type="number" value={editItem.amount} onChange={(e)=>update("amount",parseFloat(e.target.value))}/></div>
					</Col>
					<Col xs={2}><br/>
						<Colors color={editItem.color} update={(color)=>update("color",color)}/>
						{/*<Input type="select" value={editItem.color} onChange={(e)=>update("color",e.target.value)}>
							{colors.map((ob)=>
								<option value={ob} style={{"color":ob}}><div style={{"background":ob}}>xx</div></option>)}

							</Input>*/}
					</Col>
					<Col xs={3}><br/>
						<div className={classes.stars}><Stars total={5} stars={editItem.stars} onClick={(i)=>update("stars",i)}/></div>
					</Col>
				</Row>
				{/*			
				Lisätietoja
				<div className={classes.details}><Input type="textarea" rows={5} value={editItem.details} onChange={(e)=>update("details",e.target.value)}/></div>
					*/}
		{/*
					<div><i className="fas fa-circle"/> 01.01.2020 12:23 <i className="fas fa-phone"/></div>
					<div className={classes.desc}>seliselisesleisls elil els leis le</div>
					<div><i className="fas fa-circle"/> 01.01.2020 12:23 <i className="fas fa-envelope"/></div>
					<div className={classes.desc}>seliselisesleisls elil els leis le</div>
					<div><i className="fas fa-circle"/> 01.01.2020 12:23</div>
					<div className={classes.desc}>seliselisesleisls elil els leis le</div>
					<div className={classes.time}><i className="far fa-clock"/> {newDate(data.time).printdate()} <i className="fas fa-phone"/></div>
			*/}

					<i className="far fa-clock"/>{' '}
					<DatePicker
						className={classes.dpinput}
						showTimeSelect
						showWeekNumbers
						dateFormat="dd.MM.yyyy HH:mm"
						timeFormat="HH:mm"
						selected={editItem.time?newDate(editItem.time):""}
						onChange={date => update("time",newDate(date))}
					/>
          <pre className={classes.desc}>
            <TextareaAutosize
              className="form-control"
              value={editItem.details}
              onChange={(e)=>update("details",e.target.value)}
              placeholder="Lisätietoja...."
            />
            {/*
            <Input type="textarea" rows={5} value={editItem.details} onChange={(e)=>update("details",e.target.value)} placeholder="Lisätietoja...."/>
              */}
					</pre>					


					{editItem && (editItem.tasks ||  []).filter((task)=>task.status!==ETaskStatus.DELETED).sort(sortTasks).map((task)=>{
						const time=newDate(task.time)
						const icon={
							[ETaskStatus.DONE]:"fas fa-circle "+classes.DONE,
							[ETaskStatus.TODO]:"far fa-circle "+classes.TODO,
							[ETaskStatus.DONT]:"fas fa-circle "+classes.DONT,
							[ETaskStatus.DELETED]:"fas fa-circle "+classes.DELETED,
						}[task.status] || "far fa-circle "+classes.TODO

						const action=task.action?{
							[ETaskAction.EMAIL]:"far fa-envelope",
							[ETaskAction.PHONE]:"fas fa-phone",
							[ETaskAction.MEET]:"far fa-handshake",
							[ETaskAction.TASK]:"fas fa-tasks",

						}[task.action]:""


						if(editTask===task.id) {
							return (<EditTask key={task.id} task={task} update={updateTask} close={()=>setEditTask(null)}/>)

						}
						else {							
							return (<div key={task.id} onClick={()=>setEditTask(task.id)} className={classes.taskrow+" "+classes["status-"+task.status]}>
								<div>
									<i className={icon}/>{' '}
									{time.printdate()} {time.printtime()}{' '}
									{task.target && task.target.username}{' '}
									<i className={action}/>{' '}
									{task.contact}
								</div>
								<pre className={classes.desc}>{task.description}</pre>					
							</div>)
						}
					})}


				<i className="fas fa-flag-checkered"/>{' '}
					<DatePicker
						className={classes.dpinput}

						showTimeSelect
						showWeekNumbers
						dateFormat="dd.MM.yyyy HH:mm"
						timeFormat="HH:mm"
						selected={editItem.dueDate?newDate(editItem.dueDate):""}
						onChange={date => update("dueDate",newDate(date))}
						placeholderText="Aseta määräpäivä"
					/>

        </ModalBody>
				<ModalFooter>
					<Button disabled={editItem.status===ECardStatus.ARCHIVE} className="mr-auto" color="danger" onClick={archiveCard}>Poista</Button><br/>

					<Button className="float-right" onClick={addTask}>Lisää tehtävä</Button><br/>

					<Button color="primary" onClick={saveAndClose}>Sulje</Button>

        </ModalFooter>
			</Modal>


);
}

const mapStateToProps = (state: AppState) => ({
	cards: state.chat.cards,
	users: state.chat.users,
	customers: state.chat.customers || [],
});

export default connect(
  mapStateToProps,
	{ dbUpdate }
)(CardOpen);

