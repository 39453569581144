import axios from '../axios';	

export const ytjLoad2 = async (search:string,callback:(data:any)=>void,uri?:string) => {
	axios.get(uri || "https://avoindata.prh.fi/bis/v1?totalResults=false&maxResults=10&resultsFrom=0&name="+search,{withCredentials:false})
			.then((data)=>{
				callback(data.data)
			})
			.catch((data)=>{
				callback(null)
			})

}

export const loadDetails2 = (businessId:string,callback:(data:any)=>void) => {
		axios.get("https://avoindata.prh.fi/opendata/bis/v1/"+businessId,{withCredentials:false})
			.then((data)=>{
				callback(data.data.results[0])
			})
	}

