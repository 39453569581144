import React, { useState } from 'react';

import { connect } from "react-redux";
import { AppState } from "../../store";


import { Badge, Col, Row } from 'reactstrap';

import { ICard, ECardStatus, ITask, ETaskAction } from '../../store/chat/types';

import { newDate } from '../../newDate';
import { dbUpdate } from "../../store/chat/actions";

import Stars from '../Stars/Stars';

import CardOpen from '../Card/CardOpen';

interface IProps {
	cards:ICard[];
	card:ICard;
	task:ITask;
	dbUpdate: typeof dbUpdate;
}

const classes=require('../Card/Card.module.scss');

const Task: React.FC<IProps> = ({
	cards,
	card,
	task,
	dbUpdate,
}) => {
	const [isOpen,setIsOpen]=useState(false);

	const close = () => setIsOpen(false);
	const open = () => setIsOpen(true);

	const time=newDate(task.time)

	const action=task.action?{
		[ETaskAction.EMAIL]:"far fa-envelope",
		[ETaskAction.PHONE]:"fas fa-phone",
		[ETaskAction.MEET]:"far fa-handshake",
		[ETaskAction.TASK]:"fas fa-tasks",
	}[task.action]:""

	const cardStatus=card.status?{
		[ECardStatus.NEW]:"UUSI",
		[ECardStatus.CONTACTED]:"KONTAKTOITU",
		[ECardStatus.OFFER]:"TARJOUS",
		[ECardStatus.WON]:"VOITETTU",
		[ECardStatus.ARCHIVE]:"POISTETTU",

	}[card.status]:""

	const late=time<new Date()?classes.late:""
	const today=late?"":(time.printdate()===newDate(new Date()).printdate()?classes.today:"")
	const future=late || today?"":classes.future

	return !card?null:(<div className={late+" "+today+" "+future+" "+classes.container+" "+(card.color?classes[card.color]:"")} onClick={open}>
		<Row>
			<Col>
				<div className={classes.header}>
					{time.printdate()} {time.printtime()}{' '}
					{task.target && task.target.username}{' '}
					<i className={action}/> {task.contact}
				</div>
				<div>{card.header}</div>

				<pre className={classes.desc}>{task.description}</pre>					
			</Col>
			<Col xs={3} className="text-right">
				<div className={classes.stars}><Stars total={5} stars={card.stars}/></div>
				<Badge>{cardStatus}</Badge>
			</Col>
		</Row>

		<CardOpen isOpen={isOpen} close={close} data={card} hilight={task.id}/>
	</div>);
}

const mapStateToProps = (state: AppState) => ({
	cards: state.chat.cards,
});

export default connect(
  mapStateToProps,
	{ dbUpdate }
)(Task);

